import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./ReportBuilder.module.css";

function stripDate(date) {
  return date.split("T")[0];
}

const EditableTitle = forwardRef(({ initialTitle, report }, ref) => {
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.innerText = initialTitle;
    }
  }, [initialTitle]);

  useImperativeHandle(ref, () => ({
    getTitle: () => titleRef.current.innerText,
  }));

  return (
    <div className={styles.reportTitleContainer}>
      <div className={styles.titleMeta}>
        <div>
          Created by {report.user?.username} on{" "}
          {stripDate(report.creation_date)}
        </div>
        <div>
          Last Modified by {report.updated_by?.username} on {stripDate(report.updated_at)}
        </div>
      </div>
      <div
        ref={titleRef}
        contentEditable={true}
        suppressContentEditableWarning={true}
        className={styles.reportTitleInput}
      />
      <div style={{ display: "flex", gap: 8.94, alignItems: "center" }}>
        <img
          className={styles.reformIcon}
          alt=""
          src={`${process.env.PUBLIC_URL}/logo.svg`}
        />
        <div className={styles.titleReform}>Reform</div>
      </div>
    </div>
  );
});

export default React.memo(EditableTitle);
